@keyframes moveCircles {
    0%,
    100% {
      transform: translateY(0) translateX(0);
    }
    25% {
      transform: translateY(400px) translateX(400px);
    }
    50% {
      transform: translateY(100px) translateX(-400px);
    }
    75% {
      transform: translateY(-350px) translateX(350px);
    }
  }
  
  .animate-circles {
    position: absolute;
    animation: moveCircles 30s infinite ease-in-out;
  }
  
  .image {
    position: relative;
    /* top: 50%;
    left: 50%; */
    transform: translate(-90%, -10%);
    background-size: cover;
    background-position: center;
    width: 0; 
    height: 100%;
    transition: width 1s ease; 
  }
  
  .container:hover .image {
    width: 100%; 
  }
  
  .animate-image {
    position: relative; 
    overflow: hidden; 
  }
  